import { createModalEvent } from '@/js/components/modals';
import { defineModule } from '@/js/utils/helpers';

const getElements = () => ({
  tiles: document.querySelectorAll<HTMLLIElement>(
    '.content-block--request-tiles li[data-municipality]',
  ),
  modalTriggers: document.querySelectorAll<HTMLElement>(
    '.content-block--request-tiles [data-modal-iframe-src]',
  ),
});

const onIframeModalOpen = (event: Event) => {
  const {
    detail: { modalElement, triggerElement },
  } = event as ReturnType<typeof createModalEvent>;

  if (!(triggerElement instanceof HTMLElement)) return;

  const { modalIframeSrc } = triggerElement.dataset;
  if (typeof modalIframeSrc !== 'string') return;

  const iframe = modalElement.querySelector('iframe') as HTMLIFrameElement;
  if (!iframe) return;

  iframe.src = modalIframeSrc;
  iframe.focus();
};

export default defineModule(
  () => {
    const { tiles } = getElements();

    tiles.forEach((tile) => {
      const { municipality } = tile.dataset;
      const svg = tile.querySelector('svg') as SVGElement;

      const municipalityPath = svg.querySelector(
        `path[data-municipality='${municipality}']`,
      ) as SVGPathElement;

      const bbox = municipalityPath.getBBox();
      svg.setAttribute(
        'viewBox',
        `${bbox.x} ${bbox.y} ${bbox.width} ${bbox.height}`,
      );
      municipalityPath.classList.add('active');
    });

    document.addEventListener('open-modal:iframe', onIframeModalOpen, {
      passive: true,
    });
  },
  () => {
    document.removeEventListener('open-modal:iframe', onIframeModalOpen);
  },
);
