import { BREAKPOINT_MEDIA_QUERIES } from '../utils/breakpoints';
import { defineModule, nextTick } from '../utils/helpers';
import {
  Overlay,
  hasAnyOpenOverlay,
  hasOpenOverlay,
  toggleOverlay,
} from '../utils/overlays';

const getElements = () => ({
  navbar: document.querySelector<HTMLElement>('.navbar__nav'),
  menuElement: document.querySelector<HTMLElement>('#navbar__menu'),
  menuTogglerElement: document.querySelector<HTMLElement>('.menu__toggler'),
  subMenuTogglerElements: document.querySelectorAll<HTMLElement>(
    '#navbar__menu .sub-menu__toggle',
  ),
  navSubItems: document.querySelectorAll<HTMLLIElement>(
    '#navbar__menu .menu-item-has-children',
  ),
});

export const toggleMenu = (force?: boolean) => {
  const { menuElement, menuTogglerElement } = getElements();
  if (!menuElement || !menuTogglerElement) return;

  toggleOverlay(
    Overlay.MENU,
    menuElement.classList.toggle('menu--open', force),
  );
  menuTogglerElement.ariaExpanded = `${hasOpenOverlay(Overlay.MENU)}`;
};

const onMenuTogglerClick = () => toggleMenu();

const onSubmenuToggle = (
  event: MouseEvent,
  force?: boolean,
  button?: HTMLButtonElement,
) => {
  if (!BREAKPOINT_MEDIA_QUERIES.lg.matches && event.type !== 'click') return;

  const btn = button ?? (event.currentTarget as HTMLButtonElement);

  event.preventDefault?.();

  // close any other sub-menu's
  const { subMenuTogglerElements } = getElements();
  [...subMenuTogglerElements]
    .filter((el) => el !== btn)
    .forEach((el) => el.setAttribute('aria-expanded', 'false'));

  const expand = force ?? btn.getAttribute('aria-expanded') === 'false';
  btn.setAttribute('aria-expanded', expand.toString());
};

const toggleMenuTransitions = (force: boolean) => {
  const { navbar } = getElements();
  if (!navbar) return;

  navbar.classList.toggle('navbar--no-transitions', force);
};

const navbarBreakpointChecker = () => {
  const { navbar, subMenuTogglerElements } = getElements();
  if (!navbar) return;

  toggleMenuTransitions(true);

  toggleMenu(false);
  subMenuTogglerElements.forEach((el) =>
    el.setAttribute('aria-expanded', 'false'),
  );

  nextTick(() => toggleMenuTransitions(false));
};

const onPointerEnter = (event: PointerEvent) => {
  const li = event.currentTarget as HTMLLIElement;
  const btn = li.querySelector('.sub-menu__toggle') as HTMLButtonElement;

  onSubmenuToggle({ ...event, currentTarget: btn }, true, btn);
};

const onPointerLeave = (event: PointerEvent) => {
  const li = event.currentTarget as HTMLLIElement;
  const btn = li.querySelector('.sub-menu__toggle') as HTMLButtonElement;

  onSubmenuToggle({ ...event, currentTarget: btn }, false, btn);
};

const onMenuItemLinkClick = (event: Event) => {
  if (BREAKPOINT_MEDIA_QUERIES.lg.matches || !hasAnyOpenOverlay()) return;

  const link = event.target;
  if (!link || !(link instanceof HTMLAnchorElement)) return;

  if (link.href !== window.location.href) return;

  toggleMenu(false);
};

export default defineModule(
  () => {
    const { menuTogglerElement, subMenuTogglerElements, navSubItems } =
      getElements();
    menuTogglerElement?.addEventListener('click', onMenuTogglerClick);
    subMenuTogglerElements.forEach((element) =>
      element.addEventListener('click', onSubmenuToggle),
    );
    navSubItems.forEach((li) => {
      li.addEventListener('pointerenter', onPointerEnter);
      li.addEventListener('pointerleave', onPointerLeave);
    });

    BREAKPOINT_MEDIA_QUERIES.lg.addEventListener(
      'change',
      navbarBreakpointChecker,
      { passive: true },
    );

    document.addEventListener('click', onMenuItemLinkClick);
  },
  () => {
    const { menuTogglerElement, subMenuTogglerElements, navSubItems } =
      getElements();

    menuTogglerElement?.removeEventListener('click', onMenuTogglerClick);
    subMenuTogglerElements.forEach((element) =>
      element.removeEventListener('click', onSubmenuToggle),
    );

    navSubItems.forEach((li) => {
      li.removeEventListener('pointerenter', onPointerEnter);
      li.removeEventListener('pointerleave', onPointerLeave);
    });
    BREAKPOINT_MEDIA_QUERIES.lg.removeEventListener(
      'change',
      navbarBreakpointChecker,
    );

    toggleMenu(false);
  },
);
